import React from 'react'
import { Link, graphql } from 'gatsby'
import { Layout, SEO } from '../components'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <section role="main" className="container p-5">
          <h1 className="display-4 mt-5">Something's wrong here...</h1>
          <p className="lead">We can't find the page you're looking for.<br/>Click on any of the link above. If it still occurs, let us know.</p>
          <Link className="btn btn-outline-secondary btn-lg m-2" to="/">&larr; Back to the main page</Link>
          <Link className="btn btn-outline-primary btn-lg m-2" to="/contact/">Contact us?</Link>
        </section>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`